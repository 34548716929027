import React from 'react';
import * as css from './stamp.module.css';

export const Stamp = ({ children, className }) => {
  return (
    <svg
      className={`${className} ${css.svg}`}
      width="250px"
      height="250px"
      viewBox="0 0 250 250"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g stroke="#000000" strokeWidth="3">
          <circle cx="125" cy="125" r="99.5"></circle>
          <circle cx="125" cy="125" r="123.5"></circle>
          <polygon points="55 95 125 26 195 95 195 195 55 195"></polygon>
          <path d="M55,95 L195,95"></path>
          <polyline
            strokeLinejoin="round"
            points="125 195 125 95 55 195"
          ></polyline>
        </g>
      </g>
    </svg>
  );
};
