import { Link } from 'gatsby';
import React from 'react';
import * as css from './page-header.module.css';
import { Stamp } from './stamp';

const variantClassNameMap = {
  home: css.homeVariant,
  project: css.projectVariant,
};

export const PageHeader = ({
  children,
  className,
  title,
  subtitle,
  variant,
  logo,
  categoryLabel,
}) => {
  return (
    <header className={`${css.wrapper} ${variantClassNameMap[variant]}`}>
      <Stamp />
      <div>
        {variant === 'project' ? (
          <div>
            <Link to="/">Dan Dean</Link> / {categoryLabel}:
          </div>
        ) : (
          ''
        )}
        <h1>{title}</h1>
        {variant === 'home' && subtitle ? <h2>{subtitle}</h2> : ''}
      </div>
    </header>
  );
};
